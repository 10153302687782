var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ListItems',{attrs:{"cols":_vm.cols_by_type,"items":_vm.products,"show_expand":_vm.is_included,"expanded":_vm.expanded,"item-key":"id","not_resizeble":true},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]},proxy:true},{key:"item.nomenclature",fn:function(ref){
var item = ref.item;
return [_c('span',{class:[_vm.$style.nomenclatureCell, 'mb-2 overflow-text'],attrs:{"title":item.nomenclature}},[_vm._v(" "+_vm._s(item.nomenclature)+" ")]),_c('span',[_vm._v(" "+_vm._s(item.product_code)+" ")])]}},{key:"item.order_status",fn:function(ref){
var item = ref.item;
return [(item.order_status)?_c('ChipStatus',{attrs:{"status":_vm.get_order_status(item)}},[_vm._v(" "+_vm._s(item.order_status.name)+" ")]):_vm._e()]}},{key:"item.order_id",fn:function(ref){
var item = ref.item;
return [(item.order_external_number === null)?_c('v-tooltip',{attrs:{"bottom":"","color":"primary darken-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:_vm.$style.icon,attrs:{"size":"21px"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.reason)+" ")]):[_vm._v(" "+_vm._s(item.order_external_number)+" ")]]}},{key:"item.order_payment_status",fn:function(ref){
var item = ref.item;
return [(_vm.get_payment_status(item))?_c('PaymentStatus',{attrs:{"order_payment_status":_vm.get_payment_status(item)}}):_vm._e()]}},{key:"item.total_price_rub",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.total_price_rub))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pr-4 py-3 text-body-1",attrs:{"colspan":headers.length}},[_c('div',{class:_vm.$style.expandedItem},[_c('span',{class:_vm.$style.expandedText},[_vm._v(" Цена: ")]),_c('span',{class:_vm.$style.expandedVal},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.price_rub))+" ")])]),_c('div',{class:_vm.$style.expandedItem},[_c('span',{class:_vm.$style.expandedText},[_vm._v(" № реализации: ")]),_c('span',{class:_vm.$style.expandedVal},[(_vm.has_contract_number(item))?_c('v-btn',{attrs:{"text":""},on:{"click":_vm.go_to_dispatches}},[_vm._v(" "+_vm._s(item.number_realization)+" ")]):_c('b',[_vm._v(" – ")])],1)])])]}}],null,true)}),_c('ConfirmDialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }